import {
  Checkbox, FormControlLabel, FormGroup,
} from '@mui/material'
import { handleChangeShareGroup } from 'common/functions'
import terms from 'common/terms'
import CustomButton from 'components/CustomButton/CustomButton'
import CustomModal from 'components/CustomModal/CustomModal'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Group, GroupFormatted, Zone } from 'reducers/zones/types'
import { patchZones } from 'reducers/zones/zones.thunks'

import './zoneModal.scss'

interface Props {
  onModal: boolean
  selectedZone: Zone,
  handleClose: () => void
}

const ShareZoneModal = ({
  onModal, selectedZone, handleClose,
}: Props): ReactElement => {
  const dispatch = useDispatch()
  const [groupList, setGroupList] = useState<GroupFormatted[]>([])

  useEffect(() => {
    if (selectedZone) {
      const newGroupList = selectedZone.groups.map((group: Group) => ({
        ...group,
        check: !!group.permission,
      }))
      setGroupList(newGroupList)
    }
  }, [selectedZone])

  const handlePatchZone = () => {
    dispatch(patchZones(
      {
        zoneId: selectedZone.id,
        id: selectedZone.id,
        groups: groupList.map(group => ({
          group: group.group,
          permission: group.permission,
        })),
      },
    ))
    handleClose()
  }

  return (
    <CustomModal
      open={onModal}
      handleClose={handleClose}
      title={terms.Zones.ShareZone.title}
      className="modal-zone-share"
    >
      <FormGroup
        className="select-zone-share-form"
      >
        {groupList.map(group => (
          <div
            key={group.group}
            className="select-zone-share-menu"
          >
            <FormControlLabel
              control={(
                <Checkbox
                  onChange={e => handleChangeShareGroup(e, group.group, 'check', groupList, setGroupList)}
                  className="select-zone-share-checkbox"
                  checked={group.check}
                />
              )}
              label={group.group_name}
            />
            <select
              className="select-zone-share-select"
              disabled={!group.check}
              onChange={e => handleChangeShareGroup(e, group.group, 'select', groupList, setGroupList)}
              value={group.permission}
            >
              <option value="">{terms.Zones.ShareZone.optionSelect.none}</option>
              <option value="view">{terms.Zones.ShareZone.optionSelect.read}</option>
              <option value="edit">{terms.Zones.ShareZone.optionSelect.write}</option>
            </select>
          </div>

        ))}

      </FormGroup>
      <CustomButton
        className="modal-zone-share-button"
        handleClick={handlePatchZone}
        title={terms.Zones.ShareZone.labelButton}
        variant="primary"
      />
    </CustomModal>
  )
}

export default ShareZoneModal
