import {
  Dispatch, ReactElement, SetStateAction, useEffect, useState,
} from 'react'
import { BarDatum, ResponsiveBar } from '@nivo/bar'
import { RequestManager } from '@osrdata/app_core/dist/requests'
import { usePrevious } from 'utils/hooks'
import terms from 'common/terms'
import Loader from 'components/Loader/Loader'
import { Widget } from 'reducers/boards/types'
import { Zone } from 'reducers/zones/types'
import { chartColors } from '../Wrapper'
import Legends from '../Legends/Legends'
import CustomBarComponent from './CustomBar'

import './Bar.scss'

type Props = {
  widget: Widget
  zone: Zone
  requestManager?: RequestManager
  isPreview?: boolean
  isConfLoading?: boolean
  canceled?: boolean
  onMetricLoad?: Dispatch<SetStateAction<boolean>>
}

type Metric = {
  meta: {
    [key: string]: string
  }
  data: BarDatum[]
}

const BarWidget = ({
  widget, zone, requestManager, isPreview, isConfLoading, canceled, onMetricLoad,
}: Props): ReactElement => {
  const rq = requestManager || new RequestManager()
  const KEY_LABEL = 'x'
  const [metricsData, setMetricsData] = useState<Metric>({ meta: {}, data: [] })
  const unit = metricsData?.meta?.unit || ''
  const [loading, setLoading] = useState<boolean>(false)
  const filterParams = (widget?.filterParams || []).concat(widget?.parameters)
  const previousParams = usePrevious(filterParams)
  const [error, setError] = useState<string>('')
  const keys = metricsData?.data?.at(0)
    ? Object.entries(metricsData.data[0])?.filter(
      ([k]) => k !== 'x',
    )
    : []

  const loadMetrics = async () => {
    setLoading(true)

    try {
      rq.abort()
      const response = await rq.get(
        `/usage_reseau/metrics/${widget?.metric_slug}/bar-chart`, filterParams.reduce(
          (cur, acc) => {
            cur[acc.slug] = Array.isArray(acc.value) ? acc.value.join(',') : acc.value
            return cur
          }, { zone_id: zone.id },
        ),
      ) as unknown as Metric

      setMetricsData(response)
      setError('')
    } catch (e) {
      if (e?.response?.data?.detail) {
        setError(e.response.data.detail)
      } else if (e?.message === 'canceled') {
        // Set error to empty string to avoid displaying error message when request is canceled
        setError(' ')
      } else {
        setError(terms.Widgets.errorLoadingData)
      }
    }

    setLoading(false)
  }

  useEffect(() => {
    if (!widget || !zone || canceled) {
      return
    }

    if (!isPreview) {
      loadMetrics()
    } else if (JSON.stringify(previousParams) !== JSON.stringify(filterParams)) {
      loadMetrics()
    }
  }, [widget, zone])

  useEffect(() => {
    onMetricLoad?.(loading)
  }, [loading])

  const areAllValuesZero = (obj: BarDatum) => {
    const datakeys = Object.keys(obj)
    const valuesToCheck = datakeys.map(key => obj[key]).filter((_, index) => index !== datakeys.indexOf(KEY_LABEL))
    return valuesToCheck.every(value => value === 0)
  }

  const checkAllZero = (array: BarDatum[]) => array.every(obj => areAllValuesZero(obj))

  const handleFormatLabel = (value: string | number) => `${value}${unit}`

  if (isConfLoading || loading) {
    return <div className="bar-chart-widget"><Loader /></div>
  }

  if (canceled) {
    return <div className="bar-chart-widget"><p>Chargement de la prévisualisation annulé</p></div>
  }

  if ((metricsData?.data?.length === 0 || checkAllZero(metricsData.data) || error)) {
    return <div className="bar-chart-widget"><p>{error || terms.Widgets.noData}</p></div>
  }

  return (
    <div className="bar-chart-widget">
      <ResponsiveBar
        borderRadius={10}
        data={metricsData.data}
        colors={chartColors}
        keys={Object.keys(keys).map(k => keys[k][0])}
        theme={{
          legends: { text: { fontSize: 8 } },
          axis: { ticks: { text: { fontSize: 10 } } },
        }}
        indexBy={KEY_LABEL}
        margin={{
          top: 0, right: 50, bottom: 200, left: 50,
        }}
        barComponent={CustomBarComponent}
        padding={metricsData?.meta?.bar_mode === 'groupees' ? 0.05 : 0.5}
        axisBottom={{
          tickSize: 20,
          tickPadding: 10,
          tickRotation: 45,
        }}
        defs={[
          {
            colors: [
              {
                color: 'inherit',
                offset: 0,
              },
              {
                color: 'inherit',
                offset: 100,
                opacity: 0.3,
              },
            ],
            id: 'gradientA',
            type: 'linearGradient',
          },
        ]}
        fill={[
          {
            id: 'gradientA',
            match: '*',
          },
        ]}
        axisLeft={{ format: handleFormatLabel }}
        tooltip={({ id, value, color }) => (
          <div
            style={{
              padding: 12,
              background: 'white',
              border: `1px solid ${color}`,
              borderRadius: 4,
            }}
          >
            {`${id} : `}
            <b>{handleFormatLabel(value)}</b>
          </div>
        )}
        groupMode={metricsData?.meta?.bar_mode === 'groupees' ? 'grouped' : 'stacked'}
        label={({ value }) => `${handleFormatLabel(value)}`}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 20]] }}
      />
      <Legends legendsData={keys.map(([key]) => key)} />
    </div>
  )
}

BarWidget.defaultProps = {
  isPreview: false,
  isConfLoading: false,
  canceled: false,
  requestManager: undefined,
  onMetricLoad: () => { /* TO IMPLEMENT */ },
}

export default BarWidget
