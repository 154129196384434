import { Serie } from '@nivo/line'
import { chartColors } from '../Wrapper'

import './Legends.scss'

type Props = {
  legendsData: string[]
  handleLegendClick?: (serie: string) => void
  legendsDataFiltered?: Serie[]
  fromLineChart?: boolean
}

const Legends = ({
  legendsData, handleLegendClick, legendsDataFiltered, fromLineChart,
}: Props) => {
  const isSerieVisible = (array: Serie[], id: string) => array?.find(object => object.id === id)

  return (
    <div className="legends">
      {legendsData.map((serie, index) => (
        <div
          key={serie}
          style={{ cursor: fromLineChart ? 'pointer' : 'default' }}
          aria-hidden
          className="legend"
          onClick={() => handleLegendClick(serie)}
        >
          <div
            className="color"
            style={{
              backgroundColor:
              (fromLineChart && !isSerieVisible(legendsDataFiltered, serie)) ? 'lightgray' : chartColors[index],
            }}
          />
          <p
            style={{
              color:
              (fromLineChart && !isSerieVisible(legendsDataFiltered, serie)) ? 'gray' : 'black',
            }}
          >
            {serie}
          </p>
        </div>
      ))}
    </div>
  )
}

Legends.defaultProps = {
  handleLegendClick: () => { /* to implement */ },
  legendsDataFiltered: undefined,
  fromLineChart: false,
}

export default Legends
