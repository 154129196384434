/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import {
  get, patch, post, deleteRequest,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { FilterSettings, FilterSortDirection } from 'reducers/types'
import { SnackbarSeverity } from 'reducers/app/types'
import { setSnackbar } from 'reducers/app/app.reducers'
import terms from 'common/terms'
import {
  ParamsCreateBoard, ParamsCreateWidget, ParamsModifyBoard, ParamsUpdateWidget,
} from './types'

const getBoards = createAsyncThunk(
  'boards/getBoards',
  async (payload: { zoneId?: string, settings?: FilterSettings }, thunkApi) => {
    const { settings, zoneId } = payload

    try {
      const response = await get('/usage_reseau/dashboards/', {
        zone_id: zoneId,
        ...(settings.defaults && { filter: 'default' }),
        ...(settings.favorites && { filter: 'favorite' }),
        ...(settings.search && { search: settings.search }),
        ordering: `${settings.sortDirection === FilterSortDirection.DESC ? '-' : ''}${settings.sortBy}`,
      })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getBoard = createAsyncThunk(
  'boards/getBoard',
  async (id: string, thunkApi) => {
    try {
      const response = await get(`/usage_reseau/dashboards/${id}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postBoard = createAsyncThunk(
  'boards/postBoard',
  async (params: ParamsCreateBoard, thunkApi) => {
    try {
      const response = await post('/usage_reseau/dashboards/', params)
      thunkApi.dispatch(setSnackbar({
        message: terms.Dashboard.Snackbar.createSuccess,
        severity: SnackbarSeverity.SUCCESS,
      }))
      return response
    } catch (e: any) {
      thunkApi.dispatch(setSnackbar({
        message: terms.Dashboard.Snackbar.createError,
        severity: SnackbarSeverity.ERROR,
      }))
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const patchBoard = createAsyncThunk(
  'boards/patchBoard',
  async (params: ParamsModifyBoard, thunkApi) => {
    try {
      const { id, ...parameters } = params
      const response = await patch(`/usage_reseau/dashboards/${id}/`, parameters)

      thunkApi.dispatch(setSnackbar({
        message: terms.Dashboard.Snackbar.modifySuccess,
        severity: SnackbarSeverity.SUCCESS,
      }))

      return response
    } catch (e: any) {
      thunkApi.dispatch(setSnackbar({
        message: terms.Dashboard.Snackbar.modifyError,
        severity: SnackbarSeverity.ERROR,
      }))
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const copyBoard = createAsyncThunk(
  'boards/copyBoard',
  async (id: string, thunkApi) => {
    try {
      const response = await post(`/usage_reseau/dashboards/${id}/copy/`, {
        board_id: id,
      })
      thunkApi.dispatch(setSnackbar({
        message: terms.Dashboard.Snackbar.duplicateSuccess,
        severity: SnackbarSeverity.SUCCESS,
      }))
      return response
    } catch (e: any) {
      thunkApi.dispatch(setSnackbar({
        message: terms.Dashboard.Snackbar.duplicateError,
        severity: SnackbarSeverity.ERROR,
      }))
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteBoard = createAsyncThunk(
  'boards/deleteBoard',
  async (id: string, thunkApi) => {
    try {
      const response = await deleteRequest(`/usage_reseau/dashboards/${id}/`)
      thunkApi.dispatch(setSnackbar({
        message: terms.Dashboard.Snackbar.deleteSuccess,
        severity: SnackbarSeverity.SUCCESS,
      }))
      return response
    } catch (e: any) {
      thunkApi.dispatch(setSnackbar({
        message: terms.Dashboard.Snackbar.deleteError,
        severity: SnackbarSeverity.ERROR,
      }))
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postWidget = createAsyncThunk(
  'boards/postWidget',
  async (params: ParamsCreateWidget, thunkApi) => {
    try {
      const { boardId, ...parameters } = params
      const response = await post(`/usage_reseau/dashboards/${boardId}/widgets`, parameters)

      thunkApi.dispatch(setSnackbar({
        message: terms.Dashboard.Snackbar.modifySuccess,
        severity: SnackbarSeverity.SUCCESS,
      }))

      return response
    } catch (e: any) {
      thunkApi.dispatch(setSnackbar({
        message: terms.Dashboard.Snackbar.modifyError,
        severity: SnackbarSeverity.ERROR,
      }))
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const patchWidget = createAsyncThunk(
  'boards/patchWidget',
  async (params: ParamsUpdateWidget, thunkApi) => {
    try {
      const { boardId, widgetId, ...parameters } = params
      const response = await patch(`/usage_reseau/dashboards/${boardId}/widgets/${widgetId}`, parameters)

      thunkApi.dispatch(setSnackbar({
        message: terms.Dashboard.Snackbar.modifySuccess,
        severity: SnackbarSeverity.SUCCESS,
      }))

      return response
    } catch (e: any) {
      thunkApi.dispatch(setSnackbar({
        message: terms.Dashboard.Snackbar.modifyError,
        severity: SnackbarSeverity.ERROR,
      }))
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteWidget = createAsyncThunk(
  'boards/deleteWidget',
  async ({ boardId, widgetId }: { boardId: string, widgetId: string }, thunkApi) => {
    try {
      const response = await deleteRequest(`/usage_reseau/dashboards/${boardId}/widgets/${widgetId}`)

      thunkApi.dispatch(setSnackbar({
        message: terms.Dashboard.Snackbar.deleteWidgetSuccess,
        severity: SnackbarSeverity.SUCCESS,
      }))

      return response
    } catch (e: any) {
      thunkApi.dispatch(setSnackbar({
        message: terms.Dashboard.Snackbar.deleteWidgetError,
        severity: SnackbarSeverity.ERROR,
      }))
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getAvailableWidgets = createAsyncThunk(
  'boards/getWidgets',
  async (_, thunkApi) => {
    try {
      const response = await get(
        // eslint-disable-next-line max-len
        '/usage_reseau/available_widgets/?metric_slug=volume_trafic,oex_punctuality,oex_regularity,minutes_perdues,nombre_incidents&format_slug=cartography,bar-chart,line-chart,',
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export {
  getBoards,
  getBoard,
  postBoard,
  patchBoard,
  deleteBoard,
  copyBoard,
  postWidget,
  patchWidget,
  deleteWidget,
  getAvailableWidgets,
}
