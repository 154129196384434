export default (params: {slug: string, value: string | string[] | number}[]) => (slug: string) => {
  const param = params?.find(item => item.slug === slug)
  return param?.value || ''
}

/**
 * Sanitize string boolean values to real boolean
 * Do not jugde me, the backend is sending string boolean values... (sigh 😔)
 *
 * @param value - Object with string boolean values
 * @returns Object with real boolean values
 */
export const sanitizePayload = (value: {[key: string]: string}) => {
  if (!value) return {}

  return Object.entries(value).reduce((acc, [key, val]) => {
    if (val === 'true' || val === 'false') {
      acc[key] = val === 'true'
      return acc
    }

    acc[key] = val
    return acc
  }, {})
}
