import {
  Dispatch,
  ReactElement, SetStateAction, useCallback, useEffect, useState,
} from 'react'
import { Datum, ResponsiveLine, Serie } from '@nivo/line'
import { usePrevious } from 'utils/hooks'
import terms from 'common/terms'
import { Zone } from 'reducers/zones/types'
import { Widget } from 'reducers/boards/types'
import { RequestManager } from '@osrdata/app_core/dist/requests'
import Loader from 'components/Loader/Loader'
import { chartColors } from '../Wrapper'
import Legends from '../Legends/Legends'

import './Line.scss'

type Props = {
  widget: Widget
  zone: Zone
  requestManager?: RequestManager
  isPreview?: boolean
  isConfLoading?: boolean
  canceled?: boolean
  onMetricLoad?: Dispatch<SetStateAction<boolean>>
}

type Metric = {
  meta: {
    [key: string]: string
  }
  data: Serie[]
}

const LineWidget = ({
  widget, zone, requestManager, isPreview, isConfLoading, canceled, onMetricLoad,
}: Props): ReactElement => {
  const rq = requestManager || new RequestManager()
  const [metricsData, setMetricsData] = useState<Metric>({ meta: {}, data: [] })
  const unit = metricsData?.meta?.unit || ''
  const [loading, setLoading] = useState<boolean>(false)
  const [hiddenIds, setHiddenIds] = useState<(Serie['id'])[]>([])
  const [dataSet, setDataSet] = useState<Serie[]>([])
  const [error, setError] = useState<string>('')
  const filterParams = (widget?.filterParams || []).concat(widget?.parameters)
  const previousParams = usePrevious(filterParams)

  const loadMetrics = async () => {
    setLoading(true)

    try {
      rq.abort()
      const response = await rq.get(
        `/usage_reseau/metrics/${widget?.metric_slug}/line-chart`, filterParams.reduce(
          (cur, acc) => {
            cur[acc.slug] = Array.isArray(acc.value) ? acc.value.join(',') : acc.value
            return cur
          }, { zone_id: zone.id },
        ),
      ) as unknown as Metric
      setMetricsData(response)
      setError('')
    } catch (e) {
      if (e?.response?.data?.detail) {
        setError(e.response.data.detail)
      } else if (e?.message === 'canceled') {
        // Set error to empty string to avoid displaying error message when request is canceled
        setError(' ')
      } else {
        setError(terms.Widgets.errorLoadingData)
      }
    }

    setLoading(false)
  }

  useEffect(() => {
    onMetricLoad?.(loading)
  }, [loading])

  useEffect(() => {
    if (!widget || !zone || canceled) {
      return
    }

    if (!isPreview) {
      loadMetrics()
    } else if (JSON.stringify(previousParams) !== JSON.stringify(filterParams)) {
      loadMetrics()
    }
  }, [widget, zone])

  useEffect(() => {
    setDataSet(metricsData?.data.filter(item => !hiddenIds.includes(item.id)))
  }, [hiddenIds, metricsData?.data])

  const tooltipData = useCallback(({ point }) => {
    const { x } = point?.data
    const results = metricsData?.data?.map(
      (metrics, i) => ({
        ...metrics,
        data: metrics.data.filter(d => d.x === x),
        color: chartColors[i],
      }),
    ).filter(metrics => metrics.data.length > 0)

    return (
      <div className="tooltip">
        <h3>{x}</h3>
        <div className="wrapper">
          {results.map(metrics => (
            <p key={metrics.id} className="info">
              <span style={{ color: metrics.color }}>
                {metrics.id}
                {' '}
                :
                {' '}
              </span>
              {`${metrics.data[0].y}${unit}`}
            </p>
          ))}
        </div>
      </div>
    )
  }, [metricsData])

  const handleLegendClick = (e: Serie['id']) => {
    setHiddenIds(state => (state.includes((e))
      ? state.filter(item => item !== e)
      : [...state, (e)]))
  }

  const areAllYValuesZero = (data: Datum[]) => data.every(entry => entry.y === 0)
  const checkAllZero = (dataArray: Serie[]) => dataArray.every(obj => areAllYValuesZero(obj.data))
  const getDeltaBetweenMaxAndMin = (data: Serie[]) => {
    const minY = data.reduce((acc, cur) => Math.min(acc, ...cur.data.map(d => Number(d.y))), 0)
    const maxY = data.reduce((acc, cur) => Math.max(acc, ...cur.data.map(d => Number(d.y))), 0)
    return (maxY - minY) / 10
  }
  const getMinY = (data: Serie[]) => {
    const delta = getDeltaBetweenMaxAndMin(data)
    const minY = data.reduce((acc, cur) => Math.min(acc, ...cur.data.map(d => Number(d.y))), 0) - delta
    return minY
  }

  const getMaxY = (data: Serie[]) => {
    const delta = getDeltaBetweenMaxAndMin(data)
    const maxY = data.reduce((acc, cur) => Math.max(acc, ...cur.data.map(d => Number(d.y))), 0) + delta
    return maxY
  }

  const handleFormatLabel = (value: string | number) => `${value}${unit}`

  if (isConfLoading || loading) {
    return <div className="line-chart-widget"><Loader /></div>
  }

  if (canceled) {
    return <div className="line-chart-widget"><p>Chargement de la prévisualisation annulé</p></div>
  }

  if ((metricsData?.data?.length === 0 || checkAllZero(metricsData.data) || error)) {
    return <div className="line-chart-widget"><p>{error || terms.Widgets.noData}</p></div>
  }

  return (
    <div className="line-chart-widget">
      <ResponsiveLine
        data={dataSet}
        colors={chartColors}
        margin={{
          top: 50, right: 50, bottom: 80, left: 60,
        }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: getMinY(metricsData?.data),
          max: getMaxY(metricsData?.data),
          stacked: false,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 45,
          legendOffset: 36,
          legendPosition: 'middle',
        }}
        defs={[
          {
            colors: [
              {
                color: 'inherit',
                offset: 0,
              },
              {
                color: 'inherit',
                offset: 100,
                opacity: 0,
              },
            ],
            id: 'gradientA',
            type: 'linearGradient',
          },
        ]}
        fill={[
          {
            id: 'gradientA',
            match: '*',
          },
        ]}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: -40,
          legendPosition: 'middle',
          format: handleFormatLabel,
        }}
        tooltip={tooltipData}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh
        enablePoints={false}
        enableArea
        curve="monotoneX"
      />
      <Legends
        legendsData={metricsData?.data?.map(serie => serie.id.toString())}
        handleLegendClick={handleLegendClick}
        legendsDataFiltered={dataSet}
        fromLineChart
      />
    </div>
  )
}

LineWidget.defaultProps = {
  isPreview: false,
  isConfLoading: false,
  canceled: false,
  requestManager: undefined,
  onMetricLoad: () => { /* TO IMPLEMENT */ },
}

export default LineWidget
